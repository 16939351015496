// Variables

$grid: 8px;

$fontWeightBook: "Avenir LT W01_45 Book1475508";
$fontWeightRoman: "Avenir LT W01_55 Roman1475520";
$fontWeightMedium: "Avenir LT W01_65 Medium1475532";
$fontWeightHeavy: "Avenir LT W01_85 Heavy1475544";
$fontWeightBlack: "Avenir LT W01_95 Black1475556";

$fontSizeLarge: 26px;
$fontSizeNormal: 24px;
$fontSizeSmall: 18px;

$colorBlack: #040205;
$colorDarkGray: #353535;
$colorGray: #8e8d8e;
$sand: #f8c976;

$colorPurple: #532EE3;
$colorPurpleDark: #38257F;

$colorGreen: #25CA87;
$colorGreenDark: #24A871;

$colorYellow: #FFA702;
$colorYellowDark: #E99A02;

// Import

@import "reset";
@import "mixins";
@import "lib/plumber/plumber";

// Styling

body {
    @include font-smoothing(on);

    .container {
        box-sizing: border-box;
        margin: calc(8 * #{$grid}) calc(2 * #{$grid});
        width: calc(100% - 32px);

        @include for-size(sm) {
            margin: calc(12 * #{$grid}) auto;
            width: calc(600px - 32px);
        }

        @include for-size(md) {
            margin: calc(16 * #{$grid}) auto;
            width: calc(900px - 32px);
        }

        @include for-size(lg) {
            margin: calc(24 * #{$grid}) auto;
            width: 960px;
        }
    }

    section {
        width: 100%;
        display: flex;
        box-sizing: border-box;
        margin-bottom: calc(18 * #{$grid});
    }

    .content {
        width: 100%;
    }
}

// Dark

body.dark {
    background: $colorBlack;
    font-family: $fontWeightBook;
    color: $colorGray;

    a {
        color: white;
        text-decoration: underline;
        cursor: pointer;
    }

    h1,
    h2,
    h3,
    h4 {
        color: white;
        width: 100%;
        font-family: $fontWeightBlack;
    }
    .text {
        @include for-size(xs) {
            margin-bottom: calc(2 * #{$grid});
        }

        .content {
            @include for-size(sm) {
                width: 80%;
            }

            @include for-size(md) {
                width: 70%;
            }

            h1 {
                @include plumber(
                    $grid-height: 8px,
                    $baseline: 0.183,
                    $font-size: 7.25,
                    $line-height: 7,
                    $leading-top: 1,
                    $leading-bottom: 2
                );

                @include for-size(sm) {
                    @include plumber(
                        $grid-height: 8px,
                        $baseline: 0.183,
                        $font-size: 10.25,
                        $line-height: 10,
                        $leading-top: 1,
                        $leading-bottom: 2
                    );
                }

                @include for-size(md) {
                    @include plumber(
                        $grid-height: 8px,
                        $baseline: 0.183,
                        $font-size: 12.25,
                        $line-height: 12,
                        $leading-top: 1,
                        $leading-bottom: 2
                    );
                }

                span {
                    color: $colorPurple;
                    //font-size: 135%;
                }
            }
            .subTitle {
                font-family: $fontWeightHeavy;
                color: white;
                @include plumber(
                    $grid-height: 8px,
                    $baseline: 0.183,
                    $font-size: 3.2,
                    $line-height: 5,
                    $leading-top: 1,
                    $leading-bottom: 0
                );
            }
            .whatWeDo {
                @include plumber(
                    $grid-height: 8px,
                    $baseline: 0.183,
                    $font-size: 3,
                    $line-height: 5,
                    $leading-top: 0,
                    $leading-bottom: 2
                );
            }
        }
    }

    .about {
       
        .consultants {
            @include for-size(md) {
                display: flex;
            }

            .consultant {
                //margin-right: calc(2 * #{$grid});
                //margin-top: calc(2 * #{$grid});
                //padding: calc(2 * #{$grid}) calc(4 * #{$grid});
                //border-radius: 3px;

                margin-top: calc(4 * #{$grid});
                margin-bottom: calc(8 * #{$grid});

                @include for-size(md) {
                    width: 100%;
                    margin-top: 0;
                    margin-bottom: 0;
                }

                h2 {
                    font-family: $fontWeightHeavy;
                    @include plumber(
                        $grid-height: 8px,
                        $baseline: 0.183,
                        $font-size: 3.25,
                        $line-height: 4,
                        $leading-top: 1,
                        $leading-bottom: 0
                    );
                }

                .function {
                    text-transform: uppercase;
                    font-family: $fontWeightRoman;
                    @include plumber(
                        $grid-height: 8px,
                        $baseline: 0.183,
                        $font-size: 2,
                        $line-height: 3,
                        $leading-top: 0,
                        $leading-bottom: 2
                    );
                }
                .contact {
                    font-family: $fontWeightRoman;
                    color: white;
                    @include plumber(
                        $grid-height: 8px,
                        $baseline: 0.183,
                        $font-size: 2.35,
                        $line-height: 3,
                        $leading-top: 0,
                        $leading-bottom: 2
                    );
                }
            }
        }
    }
}