// Font smooting
// https://maximilianhoffmann.com/posts/better-font-rendering-on-osx

@mixin font-smoothing($value: on) {
    @if $value == on {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    @else {
        -webkit-font-smoothing: subpixel-antialiased;
        -moz-osx-font-smoothing: auto;
    }
}

// Responsive
// https://medium.freecodecamp.org/the-100-correct-way-to-do-css-breakpoints-88d6a5ba1862
@mixin for-size($size) {
  @if $size == xs {
    @media (max-width: 599px) { @content; }
  } @else if $size == sm {
    @media (min-width: 600px) { @content; }
  } @else if $size == md {
    @media (min-width: 900px) { @content; }
  } @else if $size == lg {
    @media (min-width: 1200px) { @content; }
  }
}